<template>
  <div :class="[isMobile ? 'mobile-protect-information-pdf' : '', 'protect-information-pdf']">
    <div class="information-content">
      <div class="pdf-title">个人信息收集清单</div>
      <iframe src="./pdf/web/viewer.html?file=personInformation.pdf" frameborder="0" class="pdf_box"></iframe>
      <div class="page">
        <div class="btn-back" @click="goBack">返回列表</div>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from "@/helper/utils";
export default {
  name: 'PersonInformation',
  data() {
    return {
      isMobile: isMobile(),
    };
  },
  created(){
    // 微信公众号用户协议、委托协议签署内嵌了网页，特殊处理一些样式
    if(this.$route.query.funtionOpenPage){
      document.querySelector('#app').classList.add('funtionOpenPage')
    }
  },
  mounted() {
    this.$emit('loading', false)
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
:v-deep #toolbarViewer {
  height: 22px;
}

.protect-information-pdf {
  margin-left: 34px;
  .information-content {
    padding-top: 0;
    text-align: center;
    .page {
      display: flex;
      justify-content: flex-end;
      padding: 0.4rem 0 0;
      border-top: 1px solid #dcdcdc;
      margin-top: 0.6rem;
      .btn-back {
        width: 1.4rem;
        height: 0.44rem;
        border: 1px solid #d0d0d0;
        border-radius: 0.22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.16rem;
        color: #666666;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #ee5400;
          border-color: #ee5400;
          color: #ffffff;
        }
      }
    }
    .pdf-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 0.36rem;
      color: #333333;
      font-style: normal;
      margin: 22px 0;
    }
  }
  .pdf_box {
    min-height: 365px;
    height: 40vw;
    width: 100%;
  }
}
</style>
<style lang="scss">
.funtionOpenPage{
  padding: 0 !important;
  .mask{
    top: 0 !important;
  }
  .page-wraper{
    padding: 0 !important;
  }
  header,
  .header,
  .mobile-header,
  .footer-container,
  .about-side-bar,
  .m-service,
  .page,
  .pdf-title{
    display: none !important;
  }
  .main-box{
    padding: 0 !important;
  }
  .page-mian,
  .protect-information-pdf{
    margin-left:0 !important;
  }
  .information-content{
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    .pdf_box{
      flex: 1;
    }
  }
}
</style>
